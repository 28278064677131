import React, { Component } from "react";

import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";

import {
  CHANGE_PASSWORD,
  CONFIRM_PASSWORD,
  CURRENT_PASSWORD,
  CURRENT_PASSWORD_REQUIRED,
  EMAIL,
  EMAIL_REQUIRED,
  EMAIL_WRONG_FORMAT,
  LOGIN,
  NEW_PASSWORD,
  PASSWORD,
  PASSWORD_DONOT_MATCH,
  PASSWORD_REQUIRED,
  PASSWORD_TOO_LONG,
  PASSWORD_TOO_SHORT,
  SUBMIT,
} from "../../config/app-strings";

import { RESET_LOADER } from "../../actions/Type";
import { ScaleLoader } from "react-spinners";
import {
  editProfile,
  isAuthorUserStillLoggedIn,
  login,
  updatePassword,
} from "../../actions/AuthAction";
import { resetLoader } from "../../actions/AppAction";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { toast } from "react-toastify";

class ChangePasswordForm extends Component {
  // component will unmount
  componentWillUnmount() {
    // function called when the user leaves this page
    this.props.resetLoader(RESET_LOADER);
  }

  render() {
    const { isLoading, updatePassword } = this.props;
    const { showPassword, setShowPassword } = this.props;
    const { showNewPassword, setNewPassword } = this.props;
    const { showConfirmPassword, setConfirmPassword } = this.props;
    const { authorId } = this.props;

    // Yup validation schema
    const loginFormValidationSchema = Yup.object().shape({
      currentPassword: Yup.string().required(CURRENT_PASSWORD_REQUIRED),

      newPassword: Yup.string()
        .required(PASSWORD_REQUIRED)
        .min(8, PASSWORD_TOO_SHORT)
        .max(15, PASSWORD_TOO_LONG),
    });

    const onHandleChangeInField = (e) => {
      this.setState({ [e.target.name]: e.target.value });
    };

    // Submitting login form data
    const submittingChangePassword = async (values) => {
      if (values.newPassword !== values.confirmPassword) {
        return toast.warning(PASSWORD_DONOT_MATCH);
      }

      let content = {
        data: {
          attributes: {
            current_password: values.currentPassword,
            password: values.newPassword,
            password_confirmation: values.confirmPassword,
          },
        },
      };

      updatePassword(content);
    };

    if (!isAuthorUserStillLoggedIn()) return <Navigate to={"/"} />;

    return (
      <Formik
        initialValues={{
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        }}
        validationSchema={loginFormValidationSchema}
        onSubmit={(values) => submittingChangePassword(values)}
      >
        {({ errors, touched, values, resetForm }) => (
          <Form className="flex flex-col w-full px-10 pt-10 pb-5 space-y-4">
            <div className="flex flex-col justify-start space-y-3"></div>

            <div className="flex flex-col justify-start space-y-3 w-full">
              <div className="relative flex items-center">
                <Field
                  name="currentPassword"
                  id="currentpassword"
                  type={showPassword ? "text" : "password"}
                  placeholder={CURRENT_PASSWORD}
                  className="p-3 border rounded-lg placeholder-textColor font-extralight text-textColor bg-gray-50 w-full pr-10"
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 text-gray-400"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <MdVisibilityOff
                      style={{ width: "22px", height: "20px" }}
                    />
                  ) : (
                    <MdVisibility style={{ width: "20px", height: "20px" }} />
                  )}
                </button>
              </div>

              {errors.currentPassword && touched.currentPassword ? (
                <small className="text-sm text-left text-lightbg">
                  {errors.currentPassword}
                </small>
              ) : null}
            </div>
            <div className="flex flex-col justify-start space-y-3 w-full">
              <div className="relative flex items-center">
                <Field
                  name="newPassword"
                  id="newPassword"
                  type={showNewPassword ? "text" : "password"}
                  placeholder={NEW_PASSWORD}
                  className="p-3 border rounded-lg placeholder-textColor font-extralight text-textColor bg-gray-50 w-full pr-10"
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 text-gray-400"
                  onClick={() => setNewPassword(!showNewPassword)}
                >
                  {showNewPassword ? (
                    <MdVisibilityOff
                      style={{ width: "22px", height: "20px" }}
                    />
                  ) : (
                    <MdVisibility style={{ width: "20px", height: "20px" }} />
                  )}
                </button>
              </div>

              {errors.newPassword && touched.newPassword ? (
                <small className="text-sm text-left text-lightbg">
                  {errors.newPassword}
                </small>
              ) : null}
            </div>
            <div className="flex flex-col justify-start space-y-3 w-full">
              <div className="relative flex items-center">
                <Field
                  name="confirmPassword"
                  id="confirmPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder={CONFIRM_PASSWORD}
                  className="p-3 border rounded-lg placeholder-textColor font-extralight text-textColor bg-gray-50 w-full pr-10"
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 text-gray-400"
                  onClick={() => setConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? (
                    <MdVisibilityOff
                      style={{ width: "22px", height: "20px" }}
                    />
                  ) : (
                    <MdVisibility style={{ width: "20px", height: "20px" }} />
                  )}
                </button>
              </div>

              {values.confirmPassword &&
                values.confirmPassword !== values.newPassword && (
                  <small className="text-white text-sm">
                    {PASSWORD_DONOT_MATCH}
                  </small>
                )}
            </div>

            {/* Submit button */}
            <button
              type="submit"
              className="py-2 bg-white border rounded-lg text-default hover:bg-lightDefault hover:border hover:border-white hover:text-white"
            >
              {isLoading ? (
                <ScaleLoader
                  height="20px"
                  width="4px"
                  radius="2px"
                  margin="2px"
                  color="#ffffff"
                />
              ) : (
                SUBMIT
              )}
            </button>
          </Form>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.app.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updatePassword: (content, ) =>
      dispatch(updatePassword(content)),
    resetLoader: (type) => dispatch(resetLoader(type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm);
