import React, { useEffect, useState } from "react";
import ComicBooks from "../../assets/images/comic-books.jpg";
import { instance } from "../../config/api";
import Slider from "react-slick";
import { settings } from "../Config/Carouselconfig";
import { encryptAndDecryptLocalStorage } from "../../actions/AuthAction";
import Endpoints from "../../config/endpoints";
import noDataIcon from "../../assets/images/noComics.gif";
import {
  CONTEST_VIEW_COUNT,
  NO_COMIC_BOOKS,
  RANKING_POSITION,
  TOTAL_VIEW_COUNT,
} from "../../config/app-strings";
import ShimmerIndicator from "../ShimmerIndicator/ShimmerIndicator";

const ComicBookModalSection = () => {
  const [comicBooksList, setTotalComicBooks] = useState([]);
  const [selectedComicBook, setSelectedComicBook] = useState(null);
  const [stateLoading, setStateLoading] = useState(false);

  useEffect(() => {
    fetchComicBooks();
  }, []);

  async function fetchComicBooks() {
    setStateLoading(true);
    try {
      const token = await encryptAndDecryptLocalStorage(
        localStorage.getItem("login_user_token"),
        false
      );
      const comicBooks = await instance.get(Endpoints.getAuthorComicBooks, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setTotalComicBooks(comicBooks.data.data);
      if (comicBooks.data.data.length > 0) {
        setSelectedComicBook(comicBooks.data.data[0]);
      }
    } catch (error) {
      console.error("Error fetching comic books:", error);
    }
    setStateLoading(false);
  }

  const handleComicBookClick = (comicBook) => {
    setSelectedComicBook(comicBook);
  };

  const mappingComicBooks = comicBooksList.map((item) => (
    <div
      key={item.data.comicbook_id}
      className={`relative pr-3 transition duration-500 w-auto transform group hover:scale-90 md:rounded-lg ${
        selectedComicBook &&
        selectedComicBook.data.comicbook_id === item.data.comicbook_id
          ? "selected-comic"
          : ""
      }`}
      onClick={() => handleComicBookClick(item)}
    >
      <div
        className="flex items-center justify-center text-white text-lg"
        style={{
          backgroundImage: `url(${
            item.links.image != null ? item.links.image : ComicBooks
          })`,
          backgroundSize: "cover",
          height: "150px",
          width: "150px",
          position: "relative",
        }}
      >
        {item.data.attributes.title}
        {selectedComicBook &&
          selectedComicBook.data.comicbook_id === item.data.comicbook_id && (
            <div className="absolute inset-0 bg-gradient-to-r from-blue-500 to-transparent opacity-75"></div>
          )}
      </div>
    </div>
  ));

  return (
    <div className="flex flex-col justify-between ">
      <div>
        {selectedComicBook ? (
          <div className="flex flex-col sm:flex-row px-5 space-y-3 md:space-y-0 md:space-x-4 items-start mt-5">
            <div className="md:w-1/2">
              <img
                src={selectedComicBook.links.image}
                alt={selectedComicBook.data.attributes.title}
                className="w-400px h-auto md:h-500px max-h-500px object-cover"
              />
            </div>
            <div className="w-full md:w-1/2 h-auto md:h-96 ml-0 md:ml-4">
              <p className="text-primaryText text-xl md:text-7xl leading-10">
                {selectedComicBook.data.attributes.title.toUpperCase()}
              </p>
              <p className="text-white text-lg md:text-2xl mt-2 md:mt-4">
                {selectedComicBook.data.meta.authors.data[0].data.attributes.name.toUpperCase()}
              </p>
              <p className="text-white font-thin mt-2 md:mt-4 w-full md:w-400px">
                {selectedComicBook.data.attributes.description}
              </p>
              <div className="mt-4">
                <table className=" w-full">
                  <thead>
                    <tr className="border">
                      <th className="border rounded-full border-gray-300 px-4 py-2 text-white text-lg">
                        {RANKING_POSITION}
                      </th>
                      <th className="border border-gray-300 px-4 py-2 text-white">
                        -
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border border-gray-300 px-4 py-2 text-white">
                        {CONTEST_VIEW_COUNT}
                      </td>
                      <td className="border border-gray-300 px-4 py-2 text-white">
                        {selectedComicBook.data.attributes.two_month_views}
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-gray-300 px-4 py-2 text-white">
                        {TOTAL_VIEW_COUNT}
                      </td>
                      <td className="border border-gray-300 px-4 py-2 text-white">
                        {selectedComicBook.data.attributes.no_of_views}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : stateLoading ? (
          <div className="flex flex-col md:flex-row gap-10 mt-10">
            <ShimmerIndicator count={1} height={400} width={400} />
            <ShimmerIndicator count={1} height={300} width={400} />
          </div>
        ) : (
          <div className="flex justify-center items-end h-64 mt-20 ">
            <div className="h-60 flex flex-col items-center justify-center pb-10">
              <img src={noDataIcon} alt="" className="h-24 w-28 md:h-44 md:w-48 " />
            </div>
          </div>
        )}
      </div>

      <div className="mt-10">
        {stateLoading ? (
          <div className="flex">
            <ShimmerIndicator count={3} height={200} width={220} />
          </div>
        ) : comicBooksList.length > 0 ? (
          <Slider {...settings} className="general_slider ml-3">
            {mappingComicBooks}
          </Slider>
        ) : (
          <div className=" items-center  ">
            <p className="text-white text-sm md:text-xl text-center">
              {NO_COMIC_BOOKS}
            </p>
            X
          </div>
        )}
      </div>
    </div>
  );
};

export default ComicBookModalSection;
