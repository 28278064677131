import React, { useEffect, useState } from "react";
import {
  CHANGE_PASSWORD,
  CONTRACTED_COMPANY,
  POWERED_BY,
  
} from "../../config/app-strings";

// Image import
import HeroBackground from "../../assets/images/HeroImage.jpg";
import ChangePasswordForm from "../../components/Forms/ChangePasswordForm";

import { Link, Navigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";

function ChangePassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmPassword] = useState(false);
  const [showNewPassword, setNewPassword] = useState(false);

  const authorId = localStorage.getItem("login_user_id");

  return (
    <div
      className="flex flex-col items-center justify-center min-h-screen"
      style={{
        backgroundImage: `url(${HeroBackground})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <Link
        to={"/dashboard"}
        className="absolute p-4 bg-white bg-opacity-25 rounded-full top-10 left-10"
      >
        <BiArrowBack size="26" color="#fff" />
      </Link>
      <div className="flex flex-col items-center justify-center px-5 py-16 shadow-2xl w-full md:w-1/2 lg:w-1/3 rounded-2xl">
        <p className="text-3xl md:text-4xl font-extrabold text-lightbg text-center">
          {CHANGE_PASSWORD}
        </p>
        <ChangePasswordForm
          authorId={authorId}
          showPassword={showPassword}
          setShowPassword={setShowPassword}
          showNewPassword={showNewPassword}
          setNewPassword={setNewPassword}
          showConfirmPassword={showConfirmPassword}
          setConfirmPassword={setConfirmPassword}
        />
      </div>
      <p className="absolute bottom-0 mb-10 text-bottom text-lightbg">
        {POWERED_BY}
        <span className="text-lightDefault font-bold">
          {` ${CONTRACTED_COMPANY}`}
        </span>
      </p>
    </div>
  );
}

export default ChangePassword;
