import { Component } from "react";
import { ScaleLoader } from "react-spinners";
import { DESCRIPTION, MISSING_FIELD, NAME, SUBMIT } from "../../config/app-strings";
import {
  editProfile,
  encryptAndDecryptLocalStorage,
} from "../../actions/AuthAction";
import { instance } from "../../config/api";
import { connect } from "react-redux";
import { resetLoader } from "../../actions/AppAction";
import { validateEmptyString } from "../Utility/Validation";
import { toast } from "react-toastify";


class EditProfileForm extends Component {
  state = {
    profile: {},
    name: "",
    detail: "",
    isLoading: "",
    userId: "",
    profileImage: "",
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    const authorId = localStorage.getItem("login_user_id");
    try {
      const response = await instance.get(`authors/${authorId}`, {
        headers: {
          Authorization: `Bearer ${encryptAndDecryptLocalStorage(
            localStorage.getItem("login_user_token"),
            false
          )}`,
        },
      });
      this.setState({
        profile: response.data.data,
        name: response.data.data.attributes.name,
        detail: response.data.data.attributes.detail,
        userId: response.data.data.author_id,
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  render() {
    let { name, detail, userId, profileImage } = this.state;
    const { editProfile,isEditLoading } = this.props;

    console.log(isEditLoading)

    const onHandleChangeInField = (e) => {
      this.setState({ [e.target.name]: e.target.value });
    };
    const onFormSubmit = (e) => {
      e.preventDefault();

      if (!validateEmptyString(name) || !validateEmptyString(detail)) {
        return toast.warning(MISSING_FIELD);
      }

      const image = document.querySelector("#profileImage");

      profileImage = image.files[0] !== undefined ? image.files[0] : null;

      let formDataContent = new FormData();
      formDataContent.append("data[attributes][name]", name);
      formDataContent.append("data[attributes][detail]", detail);
      if (profileImage !== null) {
        formDataContent.append("data[attributes][image]", profileImage);
      }
      formDataContent.append("_method", "PATCH");
      editProfile(formDataContent, userId);
    };

    return (
      <div className="flex flex-col w-full px-10 pt-10 pb-5 space-y-4">
        <form onSubmit={onFormSubmit} className="flex flex-col space-y-5">
          <div className="flex flex-col space-y-3">
            <input
              type="text"
              name="name"
              id="authorName"
              placeholder={NAME}
              value={name}
              onChange={onHandleChangeInField}
              className="p-3 border rounded-lg placeholder-textColor font-extralight text-textColor bg-gray-50 w-full pr-10"
            />
          </div>
          <div className="flex flex-col space-y-3">
            <textarea
              name="detail"
              id="detail"
              placeholder={DESCRIPTION}
              value={detail}
              onChange={onHandleChangeInField}
              className="p-3 border rounded-lg placeholder-textColor font-extralight text-textColor bg-gray-50 w-full pr-10"
            ></textarea>
          </div>
          <div className="flex flex-col space-y-3">
            <input
              type="file"
              name="profileImage"
              id="profileImage"
              // value={this.state.profileImage}
              onChange={onHandleChangeInField}
              className="px-3 py-2 placeholder-white border rounded-lg text-textColor focus:outline-none focus:border-default bg-gray-50"
            />
          </div>

          <button
              type="submit"
              className="py-2 bg-white border rounded-lg text-default hover:bg-lightDefault hover:border hover:border-white hover:text-white"
            >
              {isEditLoading ? (
                <ScaleLoader
                  height="20px"
                  width="4px"
                  radius="2px"
                  margin="2px"
                  color="#ffffff"
                />
              ) : (
                SUBMIT
              )}
            </button>
        </form>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isEditLoading: state.app.isEditLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    editProfile: (content, userId) => dispatch(editProfile(content, userId)),
    resetLoader: (type) => dispatch(resetLoader(type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileForm);
