import PharaniaLogo from "../../assets/images/Pharania Logo White.png";
import PharaniaLogoText from "../../assets/images/Pharania Logo Text White.png";

const Hero = () => {
  return (
    <>
      <div className="w-screen">
        <div className="absolute left-0 right-0 flex flex-col items-center space-y-10 justify-center ml-auto mr-auto top-32 lg:top-50">
          <img src={PharaniaLogo} alt="Pharania Logo" className="logo" />
          <img
            src={PharaniaLogoText}
            alt="Pharania Logo"
            className="logo-text"
          />
        </div>
      </div>
    </>
  );
};

export default Hero;
