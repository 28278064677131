import { toast } from "react-toastify";

import {
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT_SUCCESS,
  LOGOUT_FAILED,
  EDIT_PROFILE_SUCCESS,
  EDIT_PROFILE_FAILED,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILED,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
} from "../../actions/Type";
import {
  LOGIN_SUCCESSFULL,
  LOGOUT_SUCCESSFUL,
  LOGGGIN_FAILED,
  UPDATED_SUCCESSFULLY,
  EMAIL_RESET_LINK_SENT,
  RESET_PASSWORD_FAILED_MESSAGE,
} from "../../config/app-strings";

const initialState = {
  isResetPasswordSuccessful: null,
  isLoggedIn: null,
  isloading: false,
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      toast.success(LOGIN_SUCCESSFULL);
      return { ...state, isLoggedIn: true };
    case LOGIN_FAILED:
      toast.error(LOGGGIN_FAILED + action.payload);
      return { ...state, isLoggedIn: false };
    case LOGOUT_SUCCESS:
      toast.success(LOGOUT_SUCCESSFUL);
      return { ...state, isLoggedIn: false };
    case LOGOUT_FAILED:
      toast.error(action.payload);
      return { ...state, isLoggedIn: false };

    case EDIT_PROFILE_SUCCESS:
      toast.success(UPDATED_SUCCESSFULLY);
      return { ...state };

    case RESET_PASSWORD_SUCCESS:
      return { isResetPasswordSuccessful: true };
    case RESET_PASSWORD_FAILED:
      toast.error(action.payload);
      return { isResetPasswordSuccessful: false };

    case EDIT_PROFILE_FAILED:
      toast.error(RESET_PASSWORD_FAILED_MESSAGE);
      return { ...state };

    case FORGOT_PASSWORD_SUCCESS:
      toast.success(EMAIL_RESET_LINK_SENT);
      return { ...state };
    case FORGOT_PASSWORD_FAILED:
      toast.error(action.payload);
      return { ...state };

    default:
      return state;
  }
};

export default AuthReducer;
