import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NoData from "./layouts/utility/NoDate";

 import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";

import Store from "./store/Store";
import Dashboard from "./layouts/dashboard/Dashboard";
import SignIn from "./layouts/auth/SignIn";
import ChangePassword from "./layouts/changePassword/ChangePassword";
import EditProfile from "./layouts/edit-profile/EditProfile";
import ForgotPassword from "./layouts/auth/ForgotPassword";
import ResetPassword from "./layouts/auth/ResetPassword";

function App() {
  return (
    <Provider store={Store}>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" exact element={<SignIn />} />
            <Route path="/dashboard" exact element={<Dashboard />} />
            <Route path="/change-password" exact element={<ChangePassword />} />
            <Route path="/edit-profile" exact element={<EditProfile />} />
            <Route path="/forgot-password" exact element={< ForgotPassword/>} />
            <Route path="/reset-password" element={<ResetPassword />} />

            <Route path="*" element={<NoData />} />

          </Routes>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
