import { Link } from "react-router-dom";

import { BiArrowBack } from "react-icons/bi";

import HeroBackground from "../../assets/images/HeroImage.jpg";

import {
  CONTRACTED_COMPANY,
  FORGOT_PASSWORD,
  POWERED_BY,
} from "../../config/app-strings";
import FOrgotPassword from "../../components/Forms/ForgotPasswordForm";

export default function ForgotPassword() {
  return (
    <div
      className="flex flex-col items-center justify-center min-h-screen"
      style={{
        backgroundImage: `url(${HeroBackground})`,
        backgroundSize: "cover",
      }}
    >
      <Link
        to={"/"}
        className="absolute p-3 md:p-4 bg-white bg-opacity-25 rounded-full top-5 left-5 md:top-10 md:left-10 "
      >
        <BiArrowBack color="#fff" className="text-lg md:text-2xl" />
      </Link>

      <div className="flex flex-col items-center justify-center px-5 py-16 shadow-2xl md:w-1/3 rounded-2xl">
        <p className="md:text-4xl  text-xl text-center font-bold text-lightbg whitespace-nowrap">
          {FORGOT_PASSWORD}
        </p>
        <FOrgotPassword />
      </div>

      <p className="text-bottom text-white">
        {POWERED_BY}
        <a href="https://vptrading.et" className="text-lightDefault font-bold">
          {` ${CONTRACTED_COMPANY}`}
        </a>
      </p>
    </div>
  );
}
