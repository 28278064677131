import React, { useState } from "react";
import { FORGOT_PASSWORD, WELCOME } from "../../../config/app-strings";
import LoginForm from "../../../components/Forms/LoginForm";
import { Link } from "react-router-dom";

export default function Body() {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="flex flex-col items-center justify-center  py-16 shadow-2xl w-full md:w-1/2 lg:w-1/3 rounded-2xl">
      <p className="text-4xl font-extrabold text-lightbg">{WELCOME}</p>
      <LoginForm
        showPassword={showPassword}
        setShowPassword={setShowPassword}
      />
      <div className="flex flex-row items-center justify-center w-full px-10">
        <Link to="/forgot-password" className="text-white">
          {FORGOT_PASSWORD}
        </Link>
      </div>
    </div>
  );
}
