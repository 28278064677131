import { useLocation } from "react-router-dom";
import { useState } from "react";

import HeroBackground from "../../assets/images/HeroImage.jpg";
import ResetPasswordForm from "../../components/Forms/ResetPasswordForm";
import {
  CONTRACTED_COMPANY,
  POWERED_BY,
  RESET_PASSWORD,
} from "../../config/app-strings";

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const location = useLocation();

  const getToken = () => {
    const query = new URLSearchParams(location.search);
    return query.get("token");
  };

  const token = getToken();

  return (
    <div
      className="flex flex-col items-center justify-center min-h-screen"
      style={{
        backgroundImage: `url(${HeroBackground})`,
        backgroundSize: "cover",
      }}
    >
      <div className="flex flex-col items-center justify-center px-5 py-16 shadow-2xl md:w-1/3 rounded-2xl">
        <p className="lg:text-4xl  text-2xl text-center font-bold text-lightbg">
          {RESET_PASSWORD}
        </p>
        <ResetPasswordForm
          token={token}
          showPassword={showPassword}
          setShowPassword={setShowPassword}
          showConfirmPassword={showConfirmPassword}
          setShowConfirmPassword={setShowConfirmPassword}
        />
      </div>

      <p className="mt-10 mb-10 text-bottom text-white">
        {POWERED_BY}
        <a href="https://vptrading.et" className="text-lightDefault font-bold">
          {` ${CONTRACTED_COMPANY}`}
        </a>
      </p>
    </div>
  );
};

export default ResetPassword;
