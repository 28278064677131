import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";

import Endpoints from "../../config/endpoints";
import Nav from "../../components/Nav.js/Nav";
import RootCarousel from "../../components/RootCaroussel/RootCarousel";
import Hero from "./Hero";
import { instance } from "../../config/api";
import HeroBackground from "../../assets/images/HeroImage.jpg";
import { encryptAndDecryptLocalStorage } from "../../actions/AuthAction";
import Modal from "react-modal";
import noDataIcon from "../../assets/images/noComics.gif";
import { NO_COMIC_BOOKS } from "../../config/app-strings";
import ShimmerIndicator from "../../components/ShimmerIndicator/ShimmerIndicator";
import { customStyles } from "../../config/ModalConfig";
import ComicBookModalSection from "../../components/Modal/ComicBook";

function Dashboard({ isLoggedIn }) {
  return (
    <div
      className="w-screen min-h-screen flex flex-col justify-between"
      style={{
        backgroundImage: `url(${HeroBackground})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <Nav />
      <Hero />
      {
        <Modal
          isOpen={true}
          style={customStyles}
          ariaHideApp={false}
          contentLabel="Comic Book Modal"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <ComicBookModalSection />
        </Modal>
      }
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
