// Validation variables
// Validating empty string
export const validateEmptyString = (inputString) => {
    if (inputString !== "") return true;
    return false;
  };
  
  // Validating password confirmation string
  export const validatePasswordConfirmation = (
    passwordField,
    confirmPasswordField
  ) => {
    if (passwordField === confirmPasswordField) return true;
    return false;
  };
  
  // Validating full name length
  export const validateFullNameLength = (inputFullName) => {
    if (inputFullName.length < 50 && inputFullName.length > 5) return true;
    return false;
  };
  
  // Validating year length
  export const validateYearLength = (inputYear) => {
    if (inputYear.length === 4) return true;
    return false;
  };
  
  // Validating phone number length
  export const validatePhoneNumber = (inputPhoneNumber) => {
    if (inputPhoneNumber.length < 15 && inputPhoneNumber.length > 7) return true;
    if (validateOnlyNumbers(inputPhoneNumber)) return true;
    return false;
  };
  
  // Validating email address format
  export const validateEmail = (inputEmail) => {
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(inputEmail)) return true;
    return false;
  };
  
  // Validating only number field
  export const validateOnlyNumbers = (inputNumberOnlyField) => {
    if (/\D/.test(inputNumberOnlyField)) return false;
    return true;
  };
  
  // Validating only letter field
  export const validateOnlyLetter = (inputLetterOnlyField) => {
    if (/^[a-zA-Z\s]*$/.test(inputLetterOnlyField)) return true;
    return false;
  };
  