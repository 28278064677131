// App
export const START_LOADER = "START_LOADER";
export const STOP_LOADER = "STOP_LOADER";
export const RESET_LOADER = "RESET_LOADER";

// Auth
export const UNAUTHENTICATED = "UNAUTHENTICATED";
export const UNAUTHORIZED = "UNAUTHORIZED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILED = "LOGOUT_FAILED";

export const OPERATION_SUCCESS = "OPERATION_SUCCESS";
export const OPERATION_FAILED = "OPERATION_FAILED";

export const EDIT_PROFILE_START_LOADER = "EDIT_PROFILE_START_LOADER";
export const EDIT_PROFILE_STOP_LOADER = "EDIT_PROFILE_STOP_LOADER";
export const EDIT_PROFILE_RESET_LOADER = "EDIT_PROFILE_RESET_LOADER";

export const EDIT_PROFILE_SUCCESS = "EDIT_PROFILE_SUCCESS";
export const EDIT_PROFILE_FAILED = "EDIT_PROFILE_FAILED";

export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILED = "FORGOT_PASSWORD_FAILED";

export const FORGOT_PASSWORD_START_LOADER = "FORGOT_PASSWORD_START_LOADER";
export const FORGOT_PASSWORD_STOP_LOADER = "FORGOT_PASSWORD_STOP_LOADER";
export const FORGOT_PASSWORD_RESET_LOADER = "RESET_PASSWORD_RESET_LOADER";

export const RESET_PASSWORD_START_LOADER = "RESET_PASSWORD_START_LOADER";
export const RESET_PASSWORD_STOP_LOADER = "RESET_PASSWORD_STOP_LOADER";

export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";
