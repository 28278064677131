class Endpoints {
  static login = "/login";
  static getUser = "/user";
  static logout = "api/logout";
  static getCsrfCookie = "/sanctum/csrf-cookie";
  static getAuthorComicBooks = "/author-comicbooks";
}

export default Endpoints;

