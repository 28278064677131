import {
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  START_LOADER,
  STOP_LOADER,
  EDIT_PROFILE_SUCCESS,
  EDIT_PROFILE_FAILED,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILED,
  FORGOT_PASSWORD_START_LOADER,
  FORGOT_PASSWORD_STOP_LOADER,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_START_LOADER,
  RESET_PASSWORD_STOP_LOADER,
  EDIT_PROFILE_START_LOADER,
  EDIT_PROFILE_STOP_LOADER,
} from "./Type";
import { instance, CSRFInstance } from "../config/api";
import { startLoader, stopLoader } from "./AppAction";
import CryptoJS from "crypto-js";
import Endpoints from "../config/endpoints";

/**
 * The login function handles user authentication by sending a POST request with email and password,
 * obtaining an access token, and retrieving user data.
 * @param email - The `email` parameter in the `login` function represents the email address entered by
 * the user for logging in. It is used as part of the login credentials along with the password to
 * authenticate the user.
 * @param password - The `password` parameter in the `login` function is the user's password that they
 * input when trying to log in to the application. It is used along with the email to authenticate the
 * user's identity during the login process.
 * @returns The `login` function is returning an asynchronous function that takes `dispatch` as an
 * argument.
 */
export const login = (email, password) => {
  return async (dispatch) => {
    const content = {
      data: {
        attributes: {
          email: email,
          password: password,
        },
      },
    };

    dispatch(startLoader(START_LOADER));

    try {
      await CSRFInstance.get("");
      var response = await instance.post(Endpoints.login, content);

      if (response.status === 200) {
        var token = response.data.data.attributes.access_token;
        var userResponse = await instance.get(Endpoints.getUser, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (userResponse.status === 200) {
          if (userResponse.data.role_id !== 3) {
            dispatch({
              type: LOGIN_FAILED,
              payload: " Cet email n'appartient pas à un auteur",
            });
            dispatch(stopLoader(STOP_LOADER));
          } else {
            setValueToLocalStorage(token, userResponse.data, userResponse);
            dispatch({ type: LOGIN_SUCCESS });
            dispatch(stopLoader(STOP_LOADER));
          }
        } else {
          dispatch({ type: LOGIN_FAILED, payload: userResponse.data.message });
          dispatch(stopLoader(STOP_LOADER));
        }
      } else {
        dispatch({ type: LOGIN_FAILED, payload: response.data.message });
        dispatch(stopLoader(STOP_LOADER));
      }
    } catch (error) {
      dispatch({ type: LOGIN_FAILED, payload: error });
      dispatch(stopLoader(STOP_LOADER));
    }
  };
};

export const editProfile = (authorFormDataContent, userId) => {
  return (dispatch) => {
    dispatch(startLoader(EDIT_PROFILE_START_LOADER));

    // Operation
    CSRFInstance.get("").then(() => {
      instance
        .post("/authors/" + userId, authorFormDataContent, {
          headers: {
            Authorization: `Bearer ${encryptAndDecryptLocalStorage(
              localStorage.getItem("login_user_token"),
              false
            )}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch({ type: EDIT_PROFILE_SUCCESS });
            dispatch(stopLoader(EDIT_PROFILE_STOP_LOADER));
          } else {
            dispatch({ type: EDIT_PROFILE_FAILED, payload: res.data.message });
            dispatch(stopLoader(EDIT_PROFILE_STOP_LOADER));
          }
        })
        .catch((err) => {
          dispatch({
            type: EDIT_PROFILE_FAILED,
            payload: err.response.data.message,
          });
          dispatch(stopLoader(EDIT_PROFILE_STOP_LOADER));
        });
    });
  };
};

export const updatePassword = (authorFormDataContent) => {
  return (dispatch) => {
    dispatch(startLoader(START_LOADER));

    // Operation
    CSRFInstance.get("").then(() => {
      instance
        .put("/update-password", authorFormDataContent, {
          headers: {
            Authorization: `Bearer ${encryptAndDecryptLocalStorage(
              localStorage.getItem("login_user_token"),
              false
            )}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch({ type: EDIT_PROFILE_SUCCESS });
            dispatch(stopLoader(STOP_LOADER));
          } else {
            dispatch({ type: EDIT_PROFILE_FAILED, payload: res.data.message });
            dispatch(stopLoader(STOP_LOADER));
          }
        })
        .catch((err) => {
          dispatch({
            type: EDIT_PROFILE_FAILED,
            payload: err.response.data.message,
          });
          dispatch(stopLoader(STOP_LOADER));
        });
    });
  };
};

export const forgotPassword = (content) => {
  return (dispatch) => {
    dispatch(startLoader(FORGOT_PASSWORD_START_LOADER));
    CSRFInstance.get("")
      .then(() => {
        instance
          .post("/forgot-password", content, {
            headers: {},
          })
          .then((response) => {
            if (response.status === 204) {
              dispatch({ type: FORGOT_PASSWORD_SUCCESS });
              dispatch(stopLoader(FORGOT_PASSWORD_STOP_LOADER));
            } else if (response.status === 422) {
              dispatch({
                type: FORGOT_PASSWORD_FAILED,
                payload: response.data.message,
              });
            } else {
              dispatch({
                type: FORGOT_PASSWORD_FAILED,
                payload: response.data.message,
              });
            }
          })
          .catch((error) => {
            const { message } = error;

            dispatch({
              type: FORGOT_PASSWORD_FAILED,
              payload: message,
            });
          })
          .finally(() => {
            dispatch(stopLoader(FORGOT_PASSWORD_STOP_LOADER));
          });
      })
      .catch((error) => {
        const { message } = error;
        dispatch({
          type: FORGOT_PASSWORD_FAILED,
          payload: message,
        });
        dispatch(stopLoader(FORGOT_PASSWORD_STOP_LOADER));
      });
  };
};

export const resetPassword = (content) => {
  return (dispatch) => {
    dispatch(startLoader(RESET_PASSWORD_START_LOADER));

    CSRFInstance.get("")
      .then(() => {
        return instance.post("/renew-password", content, {
          headers: {},
        });
      })
      .then((response) => {
        if (response.status === 200 || response.status === 204) {
          dispatch({ type: RESET_PASSWORD_SUCCESS });
        } else {
          dispatch({ type: RESET_PASSWORD_FAILED });
        }
      })
      .catch((error) => {
        dispatch({
          type: RESET_PASSWORD_FAILED,
          payload:
            error.response?.data?.message ||
            error.message ||
            "An error occurred",
        });
      })
      .finally(() => {
        dispatch(stopLoader(RESET_PASSWORD_STOP_LOADER));
      });
  };
};

/// Helper function
/**
 * The function `encryptAndDecryptLocalStorage` encrypts or decrypts a value using AES encryption with
 * a secret key.
 * @param value - The `value` parameter in the `encryptAndDecryptLocalStorage` function represents the
 * data that you want to encrypt or decrypt. It could be a string, object, or any other type of data
 * that you want to store securely in the local storage.
 * @param isEncryption - The `isEncryption` parameter is a boolean value that determines whether the
 * `value` should be encrypted or decrypted. If `isEncryption` is `true`, the `value` will be encrypted
 * using the AES encryption algorithm. If `isEncryption` is `false`, the `value` will be
 * @returns The function `encryptAndDecryptLocalStorage` returns either the encrypted value if
 * `isEncryption` is true, or the decrypted data if `isEncryption` is false.
 */
export const encryptAndDecryptLocalStorage = (value, isEncryption) => {
  let secretKey = "Ym2bp6xPKN";

  if (value === "" || value === null) return;

  if (isEncryption) {
    let encryptedValue = CryptoJS.AES.encrypt(value, secretKey);

    return encryptedValue;
  }

  let decryptedByte = CryptoJS.AES.decrypt(value, secretKey);
  let decryptedData = decryptedByte.toString(CryptoJS.enc.Utf8);

  return decryptedData;
};

/**
 * The function `setValueToLocalStorage` sets encrypted access token, user name, and role ID to the
 * local storage.
 * @param accessToken - An access token is a unique string of letters and numbers used to authenticate
 * a user and provide access to a system or application. It is typically generated by the server and
 * sent to the client for authorization purposes.
 * @param userData - userData is an object containing information about the user, such as their name
 * and role ID.
 */
const setValueToLocalStorage = (accessToken, userData) => {
  localStorage.setItem(
    "login_user_token",
    encryptAndDecryptLocalStorage(accessToken, true)
  );
  localStorage.setItem("login_user_name", userData.name);
  localStorage.setItem("login_role_id", "3");
  localStorage.setItem("login_user_id", userData.id);
};

/**
 * The function checks if the author user is still logged in based on the presence of a valid access
 * token and the user role ID being 2.
 * @returns The function `isAuthorUserStillLoggedIn` returns a boolean value - `true` if the conditions
 * specified are met, and `false` otherwise.
 */
export const isAuthorUserStillLoggedIn = () => {
  let access_token = localStorage.getItem("login_user_token");

  if (
    access_token !== null &&
    access_token.length > 0 &&
    localStorage.getItem("login_role_id") === "3"
  )
    return true;

  return false;
};
