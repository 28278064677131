import React from "react";

const ShimmerIndicator = ({ count, width, height }) => {
  return (
    <div className="flex flex-row flex-wrap gap-x-5 gap-y-5">
      {Array.from({ length: count }).map((_, index) => (
        <div
          style={{ width: `${width}px`, height: `${height}px` }}
          className="bg-white rounded-lg shimmer-effect"
          key={index}
        />
      ))}
    </div>
  );
};

export default ShimmerIndicator;
