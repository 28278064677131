import { Component } from "react";
import {
  CONFIRM_GUEST_NEW_PASSWORD,
  EMAIL_FIELD,
  EMAIL_IS_REQUIRED,
  NEW_GUEST_PASSWORD,
  PASSWORD_DONOT_MATCH,
  PASSWORD_FIELD,
  PASSWORD_IS_REQUIRED,
  PASSWORD_IS_TOO_LONG,
  PASSWORD_IS_TOO_SHORT,
  SUBMIT_RESET_PASSWORD,
  WRONG_EMAIL_FORMAT,
} from "../../config/app-strings";

import { ClipLoader } from "react-spinners";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { resetPassword } from "../../actions/AuthAction";
import { connect } from "react-redux";
import { resetLoader } from "../../actions/AppAction";
import { Navigate } from "react-router-dom";

class ResetPasswordForm extends Component {
  render() {
    const {

      isResetPasswordSuccessful,
      token,
      showPassword,
      setShowPassword,
      showConfirmPassword,
      setShowConfirmPassword,
      isResetPasswordLoading,
      resetPassword,
    } = this.props;

console.log(isResetPasswordSuccessful)

   if (isResetPasswordSuccessful) return <Navigate to={"/"} />;

    const ResetPasswordFormValidationSchema = Yup.object().shape({
      email: Yup.string().required(EMAIL_IS_REQUIRED).email(WRONG_EMAIL_FORMAT),
      newPassword: Yup.string()
        .required(PASSWORD_IS_REQUIRED)
        .min(2, PASSWORD_IS_TOO_SHORT)
        .max(15, PASSWORD_IS_TOO_LONG),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], PASSWORD_DONOT_MATCH)
        .required(PASSWORD_IS_REQUIRED),
    });

    const submittingResetPassword = async (values) => {
      let content = {
        data: {
          attributes: {
            email: values.email,
            password: values.newPassword,
            password_confirmation: values.confirmPassword,
            token: token,
          },
        },
      };
      resetPassword(content);
    };

    return (
      <Formik
        initialValues={{
          email: "",
          newPassword: "",
          confirmPassword: "",
        }}
        validationSchema={ResetPasswordFormValidationSchema}
        onSubmit={(values) => submittingResetPassword(values)}
      >
        {({ errors, touched, values, resetForm }) => (
          <Form className="flex flex-col w-full px-5 pt-10 pb-5 space-y-4">
            <div className="flex flex-col justify-start space-y-3 w-full">
              <Field
                name="email"
                id="email"
                type="email"
                placeholder={EMAIL_FIELD}
                className="p-3 border rounded-lg placeholder-textColor font-extralight text-textColor bg-gray-50"
              />
              {errors.email && touched.email ? (
                <small className="text-sm text-left text-lightbg">
                  {errors.email}
                </small>
              ) : null}
              <div className="relative">
                <Field
                  name="newPassword"
                  id="newPassword"
                  type={showPassword ? "text" : "password"}
                  placeholder={"New Password"}
                  className="p-3 border rounded-lg placeholder-textColor font-extralight text-textColor bg-gray-50 w-full pr-10"
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 text-gray-400"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <MdVisibilityOff className="w-6 h-6" />
                  ) : (
                    <MdVisibility className="w-6 h-6" />
                  )}
                </button>
              </div>

              {errors.newPassword && touched.newPassword ? (
                <small className="text-sm text-left text-lightbg">
                  {errors.newPassword}
                </small>
              ) : null}
            </div>
            <div className="relative">
              <Field
                name="confirmPassword"
                id="confirmPassword"
                type={showConfirmPassword ? "text" : "password"}
                placeholder={"Confirm Password"}
                className="p-3 border rounded-lg placeholder-textColor font-extralight text-textColor bg-gray-50 w-full pr-10"
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 text-gray-400"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? (
                  <MdVisibilityOff className="w-6 h-6" />
                ) : (
                  <MdVisibility className="w-6 h-6" />
                )}
              </button>
            </div>

            {values.confirmPassword &&
              values.confirmPassword !== values.newPassword && (
                <small className="text-white text-sm">
                  {PASSWORD_DONOT_MATCH}
                </small>
              )}

            {/* Submit button */}
            <button
              type="submit"
              className="py-2  border rounded-lg  bg-white text-default "
              disabled={
                isResetPasswordLoading ||
                !values.email ||
                !values.newPassword ||
                !values.confirmPassword ||
                values.newPassword !== values.confirmPassword
              }
            >
              {isResetPasswordLoading ? (
                <ClipLoader
                  size={30}
                  width="4"
                  radius="2"
                  margin="2"
                  color="#053359"
                />
              ) : (
                "sumbit"
              )}
            </button>
          </Form>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isResetPasswordLoading: state.app.isResetPasswordLoading,

    isResetPasswordSuccessful: state.auth.isResetPasswordSuccessful,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (content) => dispatch(resetPassword(content)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm);
