import {
  START_LOADER,
  STOP_LOADER,
  RESET_LOADER,
  FORGOT_PASSWORD_START_LOADER,
  FORGOT_PASSWORD_STOP_LOADER,
  FORGOT_PASSWORD_RESET_LOADER,
  RESET_PASSWORD_START_LOADER,
  RESET_PASSWORD_STOP_LOADER,
  EDIT_PROFILE_STOP_LOADER,
  EDIT_PROFILE_RESET_LOADER,
  EDIT_PROFILE_START_LOADER,
} from "../../actions/Type";

const initialState = {
  isEditLoading: false,
  isLoading: false,
  isForgotPasswordLoading: false,
  isResetPasswordLoading: false,
};

const AppReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADER:
      return { ...state, isLoading: true };
    case STOP_LOADER:
      return { ...state, isLoading: false };

    //ForgotPassword
    case FORGOT_PASSWORD_START_LOADER:
      return { ...state, isForgotPasswordLoading: true };
    case FORGOT_PASSWORD_STOP_LOADER:
      return { ...state, isForgotPasswordLoading: false };
    case FORGOT_PASSWORD_RESET_LOADER:
      return { ...initialState, isResetLoading: false };

    case RESET_PASSWORD_START_LOADER:
      return { ...state, isResetPasswordLoading: true };
    case RESET_PASSWORD_STOP_LOADER:
      return { ...state, isResetPasswordLoading: false };

    case EDIT_PROFILE_START_LOADER:
      return { ...state, isEditLoading: true };

    case EDIT_PROFILE_STOP_LOADER:
      return { ...state, isEditLoading: false };

    case EDIT_PROFILE_RESET_LOADER:
      return { ...initialState, isEditLoading: false };

    case RESET_LOADER:
      return initialState;
    default:
      return state;
  }
};

export default AppReducer;
