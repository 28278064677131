import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { HiMenuAlt2 } from "react-icons/hi";

import { apiWithOutCredentials, instance } from "../../config/api";
import {
  encryptAndDecryptLocalStorage,
  isAuthorUserStillLoggedIn,
} from "../../actions/AuthAction";
import {
  CHANGE_PASSWORD,
  EDIT_PROFILE,
  LOGOUT,
  LOGOUT_SUCCESSFUL,
  SIGN_OUT,
} from "../../config/app-strings";
import Endpoints from "../../config/endpoints";
import { toast } from "react-toastify";
import { LOGOUT_FAILED } from "../../actions/Type";

const Nav = () => {
  const [loading, setLoading] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const history = useNavigate();
  const menuRef = useRef(null);
  const [getProfile, setProfile] = useState({});
  const [userName, setUsername] = useState("");

  useEffect(() => {
    fetchProfile();
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  async function fetchProfile() {
    const authorId = localStorage.getItem("login_user_id");
    try {
      const response = await instance.get(`authors/${authorId}`, {
        headers: {
          Authorization: `Bearer ${encryptAndDecryptLocalStorage(
            localStorage.getItem("login_user_token"),
            false
          )}`,
        },
      });

      setProfile(response.data.data);
      setUsername(response.data.data.attributes.name);
    } catch (error) {
      console.error(error);
    } finally {
    }
  }

  const onLogOutButtonClicked = async () => {
    setLoading(true);

    try {
      const getAccessToken = await encryptAndDecryptLocalStorage(
        localStorage.getItem("login_user_token"),
        false
      );
      const header = {
        headers: {
          Authorization: `Bearer ${getAccessToken}`,
        },
      };

      await apiWithOutCredentials.get(Endpoints.getCsrfCookie);

      await apiWithOutCredentials
        .post(Endpoints.logout, {}, header)
        .then(() => {
          localStorage.clear();
          toast.success(LOGOUT_SUCCESSFUL);
          history("/");
        })
        .catch((error) => {
          toast.error(LOGOUT_FAILED);
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      toast.error(LOGOUT_FAILED);
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <div className="w-screen">
      <nav className="fixed flex flex-nowrap items-center w-full  px-6 py-4 bg-transparent justify-end z-20">
        <div className="" ref={menuRef}>
          <div
            onClick={() => setMenuOpen(!menuOpen)}
            className="w-10 h-10 rounded-full bg-passOverColor flex items-center justify-center cursor-pointer "
          >
            <p className=" text-sm text-white  whitespace-nowrap  cursor-pointer">
              {userName.toUpperCase().charAt(0)}
            </p>
          </div>
          {menuOpen && (
            <div className="absolute top-full right-5  bg-white shadow-lg rounded-lg w-56">
              <div className="py-1">
                <Link
                  to={{
                    pathname: "/edit-profile",
                  }}
                  className="block w-full px-4 py-2 text-sm text-center text-gray-800 hover:bg-gray-200"
                >
                  {EDIT_PROFILE}
                </Link>
                <Link
                  to={{
                    pathname: "/change-password",
                  }}
                  className="block w-full px-4 py-2 text-sm text-center text-gray-800 hover:bg-gray-200"
                >
                  {CHANGE_PASSWORD}
                </Link>
                <button
                  onClick={onLogOutButtonClicked}
                  className="block w-full px-4 py-2 text-sm text-gray-800 hover:bg-gray-200"
                >
                  {LOGOUT}
                </button>
              </div>
            </div>
          )}
        </div>
      </nav>
    </div>
  );
};

export default Nav;
