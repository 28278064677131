import React, { useEffect, useState } from "react";
import {
  CHANGE_PASSWORD,
  CONTRACTED_COMPANY,
  EDIT_PROFILE,
  POWERED_BY,
  WELCOME,
} from "../../config/app-strings"

// Image import
import HeroBackground from "../../assets/images/HeroImage.jpg";

import {
  encryptAndDecryptLocalStorage,
  isAuthorUserStillLoggedIn,
} from "../../actions/AuthAction";
import { Link, Navigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { instance } from "../../config/api";
import EditProfileForm from "../../components/Forms/EditProfileForm";

function EditProfile() {
    const [getProfile, setAuthor] = useState([]);
    const [stateLoading, setStateLoading] = useState(false);
 
    useEffect(() => {
        fetchAuthor();
      }, []);
    
    async function fetchAuthor() {
        setStateLoading(true);
        const authorId = localStorage.getItem("login_user_id");
        try {
          const response = await instance.get(`authors/${authorId}`, {
            headers: {
              Authorization: `Bearer ${encryptAndDecryptLocalStorage(
                localStorage.getItem("login_user_token"),
                false
              )}`,
            },
          });
          setAuthor(response.data.data.data);
        } catch (error) {
          console.error(error);
        } finally {
          setStateLoading(false);
        }
      }
     



  return (
    <div
      className="flex flex-col items-center justify-center min-h-screen"
      style={{
        backgroundImage: `url(${HeroBackground})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <Link
        to={"/dashboard"}
        className="absolute p-4 bg-white bg-opacity-25 rounded-full top-10 left-10"
      >
        <BiArrowBack size="26" color="#fff" />
      </Link>
      <div className="flex flex-col items-center justify-center px-5 py-16 shadow-2xl w-full md:w-1/2 lg:w-1/3 rounded-2xl">
        <p className="text-4xl font-extrabold text-lightbg text-center">
          {EDIT_PROFILE}
        </p>
      <EditProfileForm getProfile={getProfile}  isLoading={stateLoading}/>
      </div>
      <p className="absolute bottom-0 mb-10 text-bottom text-lightbg">
        {POWERED_BY}
        <span className="text-lightDefault font-bold">
          {` ${CONTRACTED_COMPANY}`}
        </span>
      </p>
    </div>
  );
}

export default EditProfile;
