import BounceLoader from "react-spinners/BounceLoader";

import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

export const settings = {
  dots: false,
  infinite: false,
  speed: 500,

  slidesToShow: 9,
  slidesToScroll: 1,
  autoplaySpeed: 1500,
  pauseOnHover: false,
  centerMode: false,
  lazyLoad: "ondemand",
  onLazyLoad: () => <BounceLoader color="white" />,
  nextArrow: <IoIosArrowForward color="white" />,
  prevArrow: <IoIosArrowBack color="white" />,
  responsive: [
    {
      breakpoint: 1920,
      settings: {
        slidesToShow: 10,
      }
    },
    {
      breakpoint: 1536,
      settings: {
        slidesToShow: 6,
      }
    },
    {
      breakpoint: 1366,
      settings: {
        slidesToShow: 5,
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
      }
    },
    {
      breakpoint: 950,
      settings: {
        slidesToShow: 3,
      },
    },
 
    {
      breakpoint: 520,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 450,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 320,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export const single_settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  lazyLoad: true,
};
