export const WELCOME = "Bienvenue";
export const LOGIN = "Se connecter";
export const EMAIL = "Email";
export const PASSWORD = "Mot de passe";
export const NO_DATA = "Aucune donnée disponible !";
export const GO_TO_HOME = "Aller à la maison";
export const CHANGE_PASSWORD="Changer le mot de passe";
export const CURRENT_PASSWORD = "Mot de passe actue";
export const NEW_PASSWORD = "nouveau mot de passe";
export const CONFIRM_PASSWORD = "Confirmez le mot de passe";





//Dashboard
export const NO_COMIC_BOOKS="Vous n'avez actuellement aucune bande dessinée"

export const PHARANIA_COMIC_BOOK_ENGLISH = "Pharania Comic Book";
export const PHARANIA_COMIC_BOOK = "Bande dessinée Pharania";
export const PHARANIA = "Pharania";
export const POWERED_BY = "Alimenté par";
export const CONTRACTED_COMPANY = "VP Trading";
export const SIGN_IN = "Se connecter";
export const LOG_OUT = "Se déconnecter";
export const LOADING_SIGN_OUT = " chargement";
export const SIGN_OUT = "Se déconnecter";
export const LOGGING_OUT = "Déconnecter";
export const ALREADY_HAVE_ACCOUNT = "Vous avez déjà un compte?";
export const CREATE_ACCOUNT = "Créer un compte";
export const FORGOT_PASSWORD = "Mot de passe oublié?";

//auth
export const LOGGGIN_FAILED = "Login Failed!";
//forgot password

export const EMAIL_FIELD = "Email";
export const SUBMIT_FORGOT_PASSWORD="Soumettre";
export const EMAIL_IS_REQUIRED = "L'e-mail est requis";
export const WRONG_EMAIL_FORMAT = "Mauvais format d'e-mail";

export const EMAIL_RESET_LINK_SENT =  "Le lien de réinitialisation du mot de passe a été envoyé à votre adresse e-mail";
export const EMAIL_DOESNOT_EXIST =
  "Cet email n'existe pas, essayez-en un autre";


//Button

export const SUBMIT="Soumettre";
export const LOGOUT="Se déconnecter"
export const CLOSE="Fremer"
//table
export const RANKING_POSITION="Rang"
export const CONTEST_VIEW_COUNT="Nombre de vue edition en cours"
export const TOTAL_VIEW_COUNT="Nombre de vue total"


//validation
export const CURRENT_PASSWORD_REQUIRED = "Mot de passe actuel requis.";
export const MISSING_FIELD = "One or more fields must be provided.";
export const EMAIL_REQUIRED = "Votre Emai est requis";
export const EMAIL_WRONG_FORMAT = "L'e-mail est dans un mauvais format";
export const PASSWORD_REQUIRED = "Votre mot de passe est requis";
export const PASSWORD_TOO_SHORT = "Le mot de passe est trop court";
export const PASSWORD_TOO_LONG = "Le mot de passe est trop long";
export const INVALID_CHARACTER = "Un caractère invalide est utilisé";
export const CHOOSE_OPTION_TO_DISPLAY_SECTION =
  "Choisissez les options ci-dessus pour afficher la section correspondante";
export const INVALID_EMAIL = "Format d'email invalide.";
export const PASSWORD_DONOT_MATCH = "Le mot de passe ne correspond pas";

/// Toast messages
export const LOGIN_SUCCESSFULL = "Connecté avec succès";
export const LOGOUT_SUCCESSFUL = "Déconnexion réussie";
export const LOGIN_AGAIN_TO_GAIN_ACCESS = "Connectez-vous à nouveau pour y accéder.";
 
export const UPDATED_SUCCESSFULLY= "Mis à jour avec succés";

//edit profile
export const EDIT_PROFILE="Editer le profil";
export const NAME="Name";
export const DESCRIPTION="Description";

//

//reset password
export const RESET_PASSWORD = "réinitialiser le mot de passe";

export const RESET_PASSWORD_FAILED_MESSAGE = "Échec de la modification du profil";


export const PASSWORD_IS_REQUIRED="Mot de passe requis"
export const PASSWORD_IS_TOO_SHORT="Le mot de passe est trop court"
export const PASSWORD_IS_TOO_LONG="Password is too long"


export const PHARANIA_AUTHOR_SITE="https://authors.pharania.com"
