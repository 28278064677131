// External imports
import { createStore, applyMiddleware } from "redux";
import {thunk} from "redux-thunk";

// Component imports
import RootReducer from "../reducer/RootReducer";

const initState = {};
const middleware = [thunk];

const store = createStore(
  RootReducer,
  initState,
  applyMiddleware(...middleware)
);

export default store;
